<template>
  <div>
    <j-header></j-header>
    <div class="index">
      <div class="company">
        <div class="company-con">
          <j-list :listData="companyData">
            <div slot="listText" class="company-p-w">
              <p>
                浙江久冠信息技术服务有限公司，简称浙江久冠，注册资金1000万，是一家专业提供军政法系统相关行业信息化应用解决方案的供应商，公司是集IT产品研发、技术服务、应用软件研发、系统集成为一体的综合性高新技术企业，现主要从事与各省监狱、戒毒所、看守所、少管所等相关执法机构的软件研发与服务。
              </p>
              <p>
                浙江久冠拥有多项自主知识产权，获得高新技术企业、软件企业、ISO9001质量管理体系认定证书、信息系统集成及服务资质证书等，曾被评选为浙江省市场最具消费者满意品牌单位。
              </p>
              <p>
                公司研发的产品亲情可视电话APP、亲视通APP、军讯通APP、监所通APP、校园可视电话等是利用网络、多媒体音视频、区块链、云技术打造的一套无穷扩展的视讯平台，其中亲情可视电话APP很好的解决了非常时期家属探视的难题，家属与服刑人员可通过远程视频的方式进行线上面对面会见，大大简化了会见流程节省了时间与空间成本。
              </p>
              <p>
                亲情可视电话APP涵盖了监狱、戒毒所、看守所、少管所等场景的会见预约，具备审核、音视频通话、音视频存储、大数据分析等功能，采用先进的网络多媒体视音频传输技术，具备良好的兼容性和拓展性。
              </p>
            </div>
            <div slot="listImg">
              <img class="company-img" src="../../assets/company.png" alt="">
            </div>
          </j-list>
        </div>
      </div>
      <div class="solution">
        <div class="solution-con">
          <div class="solution-con-title">解决方案</div>
          <div class="solution-con-level">智慧司法一站式解决方案提供商</div>
          <div class="solution-card-list">
            <div class="solution-card">
              <j-card v-for="(item,index) in cardList" :key="index" :card-data="item"></j-card>
            </div>
          </div>
        </div>
      </div>
      <div class="news">
        <div class="news-con">
          <j-list :listData="newsData">
            <div slot="listText" class="news-p-w">
              <p>
                2020年12月13日，杭州市安徽商会党支部书记江成余 ，副会长
                霍银斌等领导来我司参观考察。公司董事长张守见、总经理谢建、
                项目经理蒋琳等人陪同参观。
              </p>
              <p>
                各位领导参观了我公司办公区域、产品陈列区与演示区。在参观过程
                中，张总向商会领导一行详细介绍了公司现有业务逻辑，同时对企业
                目前所运行项目进行了详细说明。为了满足社会需要，久冠网络每年
                都投入大量人力物力，突破技术瓶颈、加大研发力度、提升研发水平、
                努力研发符合市场需要的多功能、多场景、多业务、多范围的产品场
                景需求。
              </p>
            </div>
            <div slot="listImg">
              <img class="news-img" src="../../assets/news.png" alt="">
            </div>
          </j-list>
        </div>
      </div>
    </div>
    <j-foot></j-foot>
  </div>
</template>

<script>
import companyBg from '@/assets/company-name-bg.png'
import newBg from '@/assets/new-name-bg.png'
import JList from "@/components/JList"
import JCard from "@/components/JCard"
import card1 from '@/assets/card1.png'
import card2 from '@/assets/card2.png'
import card3 from '@/assets/card3.png'
import card4 from '@/assets/card4.png'
import card5 from '@/assets/person_active.png'
export default {
  components: {
    JList,
    JCard
  },
  data() {
    return {
      companyData: {
        name: '公司简介',
        nameBg: companyBg,
        type: 'tRight',

      },
      newsData: {
        name: '新闻资讯',
        nameBg: newBg,
        type: 'tLeft',
      },
      cardList: [
        {
          name: '智慧监狱',
          text: '久冠【亲情可视电话】监狱视频探视系统（具有商标权、软件著作权、专利权），专为监狱服刑人员家属远程视频会见而设计。是一款便民利民、安全实用的视频通信产品，为监狱服刑人员家属提供远程视频会见服务。',
          icon: card1
        },
        {
          name: '阳光戒毒所',
          text: '亲视通APP（具有商标权、软件著作权、专利权），专为戒毒学员家属远程视频会见而设计。是一款便民利民、安全实用的视频通信产品，为戒毒学员家属提供远程视频会见服务。',
          icon: card2
        },
        {
          name: '便民看守所',
          text: '久冠公检法、律师远程提审系统（具有商标权、软件著作权、专利权），专为公检法办案人员、律师远程提审犯人而设计。该系统自动身份验证、审问过程自动录像、自动合成存储、办案人员可随时调取录像进行回放或存档。',
          icon: card3
        },
        {
          name: '智慧军营',
          text: '久冠军讯通通讯系统（具有商标权、软件著作权、专利权），专为入伍士兵和其亲朋好友远程视频会见而设计。是一款便民利民、安全实用的音视频通信产品。',
          icon: card4
        },
		{
		  name: '智慧校园',
		  text: '校园可视电话（具有商标权、软件著作权、专利权），专为学校和家长远程视频通话而设计。是一款安全实用的音视频通信产品。',
		  icon: card5
		}
      ]
    }
  },
  methods: {
    clickSub() {
      this.$router.push({
        path: '/product'
      })
    }
  }
}
</script>

<style scoped>
.index{
}
.company{
  width: 100%;
  height: 1080px;
  background: url("../../assets/company-bg.png") no-repeat center center;
}
.company-con{
  width: 1200px;
  margin: 0 auto;
  padding-top: 174px;
}
.solution{
  height: 1080px;
  width: 100%;
  background: url("../../assets/solution-bg.png") no-repeat center center;
}
.solution-con{
  width: 1500px;
  margin: 0 auto;
}
.solution-con-title{
  width: 100%;
  height: 48px;
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 48px;
  color: #FFFFFF;
  opacity: 1;
  padding-top: 115px;
}
.news{
  width: 100%;
  height: 1080px;
  background: url("../../assets/company-bg.png") no-repeat center center;
}
.news-con{
  width: 1200px;
  margin: 0 auto;
  padding-top: 316px;
}
.solution-con-level{
  width: 100%;
  height: 33px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 33px;
  color: #FFFFFF;
  opacity: 1;
  text-align: center;
  margin-top: 50px;
}
.solution-card-list{
  margin-top: 140px;
}
.solution-card{
  display: flex;
  justify-content: space-between;
}
.company-p-w{
  width: 598px;
  margin-top: 60px;
}
.company-img{
  width: 440px;
  height: 550px;
}
.news-p-w{
  width: 418px;
}
.news-img{
  width: 623px;
  height: 460px;
  margin-right: 97px;
}

</style>
