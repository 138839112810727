<template>
  <div class="card">
    <div class="card-con">
      <div class="card-img">
        <img :src="cardData.icon" alt="">
      </div>
      <div class="card-title">
        {{cardData.name}}
      </div>
      <div class="card-text">
        {{cardData.text}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JCard",
  props: {
    cardData: {
      type: Object,
      default () {
        return {
          name: '',
          text: '',
          icon: ''
        };
      }
    }
  }
}
</script>

<style scoped>
.card{
  width: 280px;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 12px;
  text-align: center;
}
.card-con{
  margin: 13px;
}
.card-img img{
  margin-top: 39px;
  width: 120px;
  height: 120px;
}
.card-title{
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 42px;
  color: #474747;
  opacity: 1;
  margin-top: 50px;
}
.card-text{
  width: 244px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 24px;
  color: #474747;
  opacity: 1;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 82px;
}
</style>
